import { Button, Form } from 'react-bootstrap';

import React, { useState } from 'react'
function FormTodo({ addTodo }) {
    const [value, setValue] = useState("");
  
    const handleSubmit = e => {
      e.preventDefault();
      if (!value) return;
      addTodo(value);
      setValue("");
    };
  
    return (
        <div className="d-flex justify-content-center">
        <Form onSubmit={handleSubmit} style={{ width: '100%' }}>
          <div className="d-flex" style={{ width: '100%',marginBottom:'40px' }}>
            <Form.Control
              type="text"
              className="input flex-grow-1"
              value={value}
              onChange={(e) => setValue(e.target.value)}
              placeholder="Add new todo"
            />
            <Button variant="primary" type="submit" className="ml-2">
              Submit
            </Button>
          </div>
        </Form>
      </div>
      
      
      
    );
  }

  export default FormTodo