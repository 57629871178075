import React, { useState, useEffect } from 'react'
import { Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Todo from './Todo'
import FormTodo from './FormTodo'
import Login from './login'
import Logout from './logout'
import Profile from './profile'


function App() {
  const [todos, setTodos] = useState(() => {
    const storedTodos = localStorage.getItem("todos");
    return storedTodos ? JSON.parse(storedTodos) : [
      {
        text: "This is a sample todo",
        isDone: false,
      }
    ];
  });

  const [addedIndex, setAddedIndex] = useState(null);
  const [removingIndex, setRemovingIndex] = useState(null);


  const addTodo = text => {
    const newTodos = [...todos, { text }];
    setTodos(newTodos);
    setAddedIndex(todos.length);
};

const markTodo = index => {
  const newTodos = [...todos];
  newTodos[index].isDone = !newTodos[index].isDone;
  setTodos(newTodos);
};

const removeTodo = index => {
  setRemovingIndex(index);
  setTimeout(() => {
    const newTodos = [...todos];
    newTodos.splice(index, 1);
    setTodos(newTodos);

    setRemovingIndex(null);
  }, 300); 
};

useEffect(() => {
  saveTodosToLocalStorage(todos);
}, [todos]);

const saveTodosToLocalStorage = (todos) => {
  localStorage.setItem("todos", JSON.stringify(todos));
};

return (
  <div className="app">
    <div className="container">
    <header style={{display:'flex',justifyContent:'space-between'}}>
      <h1>Todo styff</h1>
      <div>
      <Login />
      <Logout />
      <Profile/>
      </div>
    </header>
      <h1 className="text-center mb-4">Todo List</h1>
      <FormTodo addTodo={addTodo} />
      <div>
        {todos.map((todo, index) => (
          <Card
          key={index}
          className={`
          bg-dark text-white p-2 m-2
          ${addedIndex === index ? 'fade-in' : ''}
            todo-item ${todo.isDone ? 'done' : ''} 
            ${removingIndex === index ? 'fade-out' : ''}`}
        >
          <Card.Body>
            <Todo
              key={index}
              index={index}
              todo={todo}
              markTodo={markTodo}
              removeTodo={removeTodo}
            />
          </Card.Body>
        </Card>
        ))}
      </div>
    </div>
  </div>
);
}

export default App;
